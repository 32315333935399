// Black
$dark: #22252A;
$black:#000000;
$black-2:#1A1A1A;
$black-3:#060606;
$black-4:#141414;
$black-5:#1A1818;
$trans-black: #00000029;
$trans-black-2: #00000057;
// Black


// White
$light : #ffffff;
$white-50:#f8f9fa;
$white-2:#F2F2F2;
$white-3:#F6F6F6;
$white-4:#EBEBEB;
$white-5:#f5f5f5;
$white-6:#EFEEEE;
$white-7:#F5F4F4;
$white-8:#EFECEC;
$white-9:#FBFBFE;
$white-10:#EBE9E9;
// White


// Gray
$gary-p:#8F9198;
$gray-btn:#5B5B5B;
$gray-border:#BCBCBC;
$gray-p2:#9F9F9F;
$lighht-gray:#e4e0e0;
$light-gray-2:#D8D8D8;
$light-gray-3:#B1B1B1;
$light-gray-4:#8F9091;
$light-gray-5:#A2A2A2;
$trans-gray:#70707057;
$trans-gray-2:#70707073;
$gray:#484848;
$gray-2:#707070;
$gray-3:#3A3A3B;
$gray-4:#5D6D81;
$gray-5:#3E3E3E;
$gray-6:#585858;
// Gray


// Orange
$orange: #FF9C46;
$light-orange:#F3A64E;
// Orange


// Blue
$blue: #6280FF;
$blue-2:#0088FF;
$Spiro-blue : #0DB1FF;
$light-blue: #0DB1FFBA;
$light-blue-2: #56C0F2;
$light-blue-3:#27B2C2;
$light-blue-4:#38A4D6;
$light-blue-5:#62B6FF;
// Blue


// Green
$dark-green:#08B902;
$dark-green-2:#00FF19;
$dark-green-3:#3ADB4A;
$green:#73C155;
$light-green:#9FDFCD;
$light-green-2:#F3F9F5;
$light-green-3:#D5E3D6;
$green-5 :#569F87;
$green-9: #D3FFF1;
// Green


// Purple
$purple :#9574FF;
$purple-2:#7B3DD9;
$dark-purple:#1B1464;
$light-purple:#D79AD7;
$light-purple-2:#ACAFFF;
$light-purple-3:#B2BFFF;
// Purple


// Pink
$pink :#FF3C9E;
$pink-9 : #FCEDF4;
$light-pink:#FFAAB9;
// Pink


// Red
$red:#FF0000;
$red-2:#F25656;
// Red

