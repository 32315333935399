@import './mixins/layout';
@import './theme/theme';

body {
    font-family: 'Roboto', sans-serif;
    overflow: hidden;
}

.list-inline {
    list-style: none;
    padding: 0;
    margin: 0;
}

.wrapper {
    min-height: 100vh;
    padding: 15px;
}

.wrapper::before {
    position: absolute;
    bottom: 0;
    left: 0;
}

.margin-bottom {
    margin-bottom: 22px;
}

.content {
    border-radius: 34px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    overflow: auto;
    height: 880px;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }
}

.content-section {
    height: calc(100% - 80px);
}

.app-wrapper {
    padding: 25px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.timeline-div {
    border-radius: 36px;
    opacity: 1;
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home-div1,
.home-div3 {
    margin-right: 25px;
    max-width: 100%;
    height: 100%;
}

.home-div1:not(.first-div, .recentProjects-parent) {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;

}

.first-div {
    padding-bottom: calc(94.12px - 22.22px);
}

.home-div2 {
    margin-right: 25.29px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    max-width: 100%;
    height: 100%;
}


.h1 {
    margin-top: calc(51.49px - 25px);
    font-size: 52px;
}



.p {
    font-size: 20px;
    margin-bottom: 0;
    margin-bottom: 52px;
}

.p2 {
    font-size: 13px;
    margin-bottom: 0;
    max-width: 50%;
    width: 100%;

}

.add-emp-btn {
    max-width: 181px;
    width: 100%;
    height: 45px;
    display: flex;
    align-items: center;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 7px;
}

.add-emp-btn {
    span.anticon.anticon-plus {
        font-size: 16px;
        margin-right: 10px;
    }
}

.home-div3 {
    padding: 12.94px 14.82px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    max-width: 500px;
}

.chart-p {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1;
    padding-bottom: 13.94px;
    margin-bottom: 0;
    text-align: left;
}

.products-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.products-charts-child1 {
    width: 91px;
    height: 94px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    position: relative;
}

.products-charts-child1:nth-child(1):after {
    content: '';
    position: absolute;
    top: 80%;
    left: 75%;
    width: 10px;
    height: 45px;
    z-index: 1;
    transform: rotate(-30deg);
}

.products-charts-child1:nth-child(2) {
    & :after {
        content: '';
        position: absolute;
        top: 80%;
        left: 75%;
        width: 10px;
        height: 45px;
        z-index: 1;
        transform: rotate(-30deg);
    }
}

.products-charts-child1:nth-child(3) {
    margin-right: 0;
}

.products-charts-child1:nth-child(4) {
    margin-left: 33px;

    & :after {
        content: '';
        position: absolute;
        top: -21%;
        right: 10%;
        width: 10px;
        height: 45px;
        z-index: 1;
        transform: rotate(30deg);
    }
}

.products-charts-child1:nth-child(5) {
    & :after {
        content: '';
        position: absolute;
        top: -21%;
        right: 10%;
        width: 10px;
        height: 45px;
        z-index: 1;
        transform: rotate(30deg);
    }
}

.products-charts-child2 {
    width: 81px;
    height: 84px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.products-charts-child3 {
    width: 52px;
    height: 54px;
    border-radius: 50%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    ;
}

.products-charts-child3 h1 {
    font-size: 26px;
}

.weekley-leads-chart-parent {
    padding: 13.39px 16.92px 27.86px 15.66px;
}

.weekly-div1 {
    margin-bottom: 15.18px;
}

.weekly-div1 h1 {
    font-size: 18px;
}

.monthly-div {
    h1 {
        font-size: 18px;
    }
}

.pending-task-chart-parent {
    h1 {
        font-size: 17px;
    }

    p:not(p:first-child) {
        font-size: 9px;
        letter-spacing: 0.9;
        text-align: center;
        margin-bottom: 0;
    }
}


.monthly-chart-parent {
    padding: 17px 44.65px 27px 46px;
}

.monthly-chart-parent div:first-child {
    margin-right: 58px;
}

.monthly-chart-parent h1:not(.monthly-div h1) {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: 600;
    max-width: 90%;
}

.monthly-chart-parent h1:last-child {
    padding: 0 0 0 5px;
}


.pending-task-chart-parent {
    padding: 18.97px 25.6px 10.14px 22.8px;
}


// Dashboard Responsive Table Start
.responsive-table {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;

    td:nth-child(1),
    td:nth-child(3) {
        width: 30%;
    }

    td {
        padding: 10px;

    }

    thead {
        tr {

            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.03em;
            margin-bottom: 0;

        }
    }

    tbody {

        tr {
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
            margin-bottom: 0;
            font-size: 12px;
        }

        td img {
            margin-right: 27.45px;
        }
    }

    button.status-warning-btn {
        font-size: 10px;
        border: none;
        outline: none;
        border-radius: 6px;
        padding: 4.67px 7.78px;
    }

    button.status-primary-btn {
        font-size: 10px;
        border: none;
        outline: none;
        border-radius: 6px;
        padding: 4.67px 28.332px;
    }


}
// Dashboard Responsive Table End


// Recent Activity Section Start
.currentActivity-child1 {
    padding: 19px 10px 22px;
}

.recent-activity-table {
    padding-left: 0;
    margin-bottom: 0;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 5px;

    td:not(td:nth-child(1)) {
        text-align: right;
    }

    td {
        padding: 5px;
    }

    tr {
        font-size: 10px;
        margin-bottom: 5px;

        p {
            margin-bottom: 0;
        }
    }

    thead {
        text-transform: uppercase;
        letter-spacing: 0.03em;
        margin-bottom: 0;
    }

    tbody {
        tr {
            box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
            opacity: 1;

            .activity-img {
                width: 31px;
                height: 31px;
                border-radius: 50%;
                margin-right: 5px;
            }

            p:nth-child(2) {
                font-size: 10px;
            }
        }
    }

}
// Recent Activity Section Start

button.seeAll-btn {
    font-size: 10px;
    border-radius: 6px;
    border: none;
    padding: 7px 19.4px;
    cursor: pointer;
}

// Add Project Button Start
.add-proj-btn {
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    letter-spacing: 1.1px;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 31px;
    cursor: pointer;

    span.anticon.anticon-plus {
        width: 38px;
        height: 38px;
        border-radius: 22px;
        opacity: 1;
        border: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 14px;
        font-size: 14px;
    }
}
// Add Project Button End



// Customer Inner Body Start
.InnerBodyContent {
    max-width: 100%;
    width: 100%;
    height: 100%;
    border-radius: 32px;
    opacity: 0.74;

    .customers:nth-child(1) {
        margin: 0 24px 0 38px;
    }

    .customers:nth-child(2) {
        margin: 0 24px 0 0;
    }

    .customers:nth-child(3) {
        margin: 0 24px 0 0;
    }

    .customers-parent-div {
        margin-block: 58px;

        .customers {

            .ant-progress-bg,
            .ant-progress-success-bg {
                border-radius: 0;
            }

            .ant-progress-inner {
                border-radius: 0;
            }
            border-radius: 18px;
            opacity: 1;
            width: 237px;
            height: 127px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;

            & h1 {
                margin-bottom: 0;
                font-size: 38px;
                letter-spacing: 0px;
                opacity: 1;
            }

            & p {
                font-size: 12px;
                letter-spacing: 0px;
                opacity: 1;
                margin-bottom: 0;
                font-weight: 600;
            }
        }
    }


    .projects-image {
        width: 100%;
        display: flex;
        padding-inline: 30px;

        .projectImageInner:nth-child(1) {
            background-image: url('../../assets/images/project1.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .projectInnerContent {
                text-align: center;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .projectImageInner:nth-child(2) {
            background-image: url('../../assets/images/project2.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            margin-left: -160px;
            display: flex;
            align-items: center;
            justify-content: end;

            .projectInnerContent {
                text-align: center;
                margin-right: 60px;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .projectImageInner:nth-child(3) {
            background-image: url('../../assets/images/project3.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            margin-left: -160px;
            display: flex;
            align-items: center;
            justify-content: end;

            .projectInnerContent {
                text-align: center;
                margin-right: 60px;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }

        .projectImageInner:nth-child(4) {
            background-image: url('../../assets/images/project4.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 340px;
            height: 167px;
            margin-left: -160px;
            display: flex;
            align-items: center;
            justify-content: end;

            .projectInnerContent {
                text-align: center;
                margin-right: 50px;

                span {
                    font-size: 51px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 12px;
                }
            }
        }
    }

    .customer-right-sec {
        margin-right: 55px;
        margin-top: calc(118px - 58px);
    }

}
// Customer Inner Body End




// Searchbar and Export button Start
.customer-right-sec {

    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;

    .search-bar {
        max-width: 367px;
        width: 100%;
        margin-bottom: 20px;
    }
}
// Searchbar and Export button End



// project Info Styling Start
.projectInfoBody {
    width: 100%;
    height: 100%;
    border-radius: 32px;
    opacity: 0.74;
    overflow: hidden;

    .projectInfoLeftSection {
        padding: 30px;
        width: 100%;
        height: 100%;

        & h1 {
            margin-bottom: 31px;
            font-size: 26px;
            text-transform: uppercase;
        }


        .projectName {
            width: 100%;
            margin-bottom: 31px;

            & h1 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 17px;
            }

            & p {
                font-size: 17px;
                margin: 0;
            }

        }

     

        .progressChart {
            margin-bottom: 21px;
            text-align: center;
        }

        .projectMembers {
            & p {
                font-size: 16px;
                margin-bottom: 0;
            }

            .member-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 13px;

                & p {
                    font-size: 17px;
                    margin-bottom: 0;
                }
            }

            .member-images {
                margin-bottom: 13px;
            }
        }
    }

    .projectInfoRightSection {
        width: 100%;
        height: 100%;
        border-radius: 32px;
        opacity: 1;
        padding: 45px 24px 35px 10px;


    }

}
// project Info Styling End




// Antd Table Styling Start

.ant-table-pagination.ant-pagination {
    justify-content: center;
}

.ant-table {
    table {
        border-collapse: separate;
        border-spacing: 0 10px;
        overflow: auto;
    }
}

.ant-table-thead>tr {
    th {
        position: relative;
        font-weight: 600;
        background: transparent;
        border-bottom: 0;
        padding: 10px;
        text-transform: uppercase;
    }

    th:last-child {
        text-align: center;
    }
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    visibility: hidden;
}

.ant-table-tbody>tr {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    td {
        font-size: 14px;
        font-weight: 600;
        padding: 10px;
        opacity: 1;
    }
}


.ActiveBtn {
    display: flex;
    align-items: center;
    border-radius: 5px;
    opacity: 1;

    & svg {
        margin-right: 5px;
    }
}

.editbtn {
    border-radius: 6px;
    opacity: 1;
    border: 0;
}

.deletebtn {
    border-radius: 6px;
    opacity: 1;
    border: 0;
}

.vline {
    height: 30px;
    margin-inline: 3px;
}

// Antd Table Styling End



// Ant Design Tabs Start

.card-container p {
    margin: 0;
}

.card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

.card-container>.ant-tabs-card .ant-tabs-content>.ant-tabs-tabpane {
    padding: 16px;
    border-radius: 0 14px 14px 14px;
}

.card-container>.ant-tabs-card>.ant-tabs-nav::before {
    display: none;
}

.card-container>.ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

#components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
}

[data-theme='compact'] .card-container>.ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}

[data-theme='dark'] .card-container>.ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
    border-radius: 14px 14px 0 0;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    text-shadow: 0 0 .25px currentcolor;
    font-size: 18px;
}

.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
    outline: none;
    transition: all .3s;
    font-size: 18px;
    border-bottom: 3px solid transparent;
}

// Ant Design Tabs End


// Ant design Modal Styling
.ant-modal-content {
    border-radius: 30px
}

.ant-modal-close {
    position: absolute;
    left: 100%;
}

.ant-modal-close-x {
    line-height: 0;
}

.ant-modal-body {
    padding: 19px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
    border-radius: 30px;
    overflow: hidden;
}

.ModalBody {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ModalLeftSec {
        width: 100%;
        height: 850px;
        border-radius: 24px;
        opacity: 1;
        padding: 22px 22px 29px 27px;

        & h1 {
            font-size: 24px;
        }

        .projectName {
            width: 100%;
            margin-block: 40px;

            & h1 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            & p {
                font-size: 17px;
                margin: 0;
            }

        }

        .projectDetails {
            width: 100%;
            margin-bottom: 31px;
            border-collapse: separate;
            border-spacing: 0 10px;

            & tr {
                & td:first-child {
                    font-size: 17px;
                }

                & td:last-child {
                    font-size: 17px;
                }
            }
        }

        .projectMembers {
            margin-top: 200px;

            & p {
                font-size: 16px;
                margin-bottom: 0;
            }

            .member-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 13px;

                & p {
                    font-size: 17px;
                    margin-bottom: 0;
                }
            }

            .member-images {
                margin-bottom: 13px;
            }
        }
    }

    .ModalMiddleSec {
        width: 100%;
        height: 850px;
        padding: 22px 12px 50px 43px;

        & h1 {
            font-size: 25px;
            text-transform: uppercase;
            margin-bottom: 63px;
        }

    }
    .ModalRightSec {
        width: 100%;
        height: 850px;
        border-radius: 24px;
        opacity: 1;
        padding: 50px 5px 50px 24px;

      
    }
}
// Ant design Modal Styling


// Primary Button
.primaryBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}
// Primary Button


// secondary Button
.secondaryBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}
// secondary Button

// success Button
.successBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}
// success Button

// danger Button
.dangerBtn {
    border-radius: 11px;
    opacity: 1;
    padding: 10px 55.3px;
    font-size: 17px;
}
// danger Button


// Todo App Start
.todo {
    width: 100%;
    height: 750px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: end;
    ul{
        padding-right: 20px;
        padding-left: 0;
        list-style-type: none;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
            border-radius: 34px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
            margin-block: 30px;

        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(114, 114, 114, 0.5);
            border-radius: 10px;
            margin-block: 30px;
        }
        & p{
            margin: 0;
            font-size: 17px;
            font-weight: 600;
        }
        li.todo-list {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px 20px;
            border-radius: 0px 20px 20px 20px;
            font-size: 17px;
            font-weight: 600;
        }
    }
    .todoChild {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-right: 20px;

        .ant-input-affix-wrapper {
            border-radius: 36px;
            opacity: 1;
            margin-right: 10px;

            input[type=text] {
                padding: 10px;
            }

        }

        .primaryBtn {
            padding: 15px;
            opacity: 1;
            border-radius: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

    }
}
// Todo App End


.ModalLeftSec{
    .ticketInfo{
        font-size: 24px;
    }
}


.ModalMiddleSec{
    .dangerBtn{
        margin-bottom: 63px;
        font-weight: bold;
        font-size: 20px;
        padding: 7px 46.5px;
    }
}
// Midle Section Content start
.ModalMiddleSecContent {
    height: 410px;
    padding-right: 25px;
    margin-bottom: 110px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }
  

    & p {
        margin: 0;
        font-size: 17px;
        font-weight: bold;
    }

    & p:nth-child(2) {
        margin-bottom: 20px;
    }

    & p:nth-child(4) {
        margin-bottom: 20px;
    }
}
// Middle Section Content End

// Midle Section Content 2 start
.ModalMiddleSecContent2 {
    height: 410px;
    padding-right: 25px;
    margin-bottom: 110px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }
  

    & p {
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 35px;
    }
}
// Middle Section Content 2 End


// Modal Middle Sectiion Download File Section  Start
.FilesDownloadSec {
    padding-top: 22px;
    padding-right: 20px;
    height: 200px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 34px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 10px;
        margin-block: 30px;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(114, 114, 114, 0.5);
        border-radius: 10px;
        margin-block: 30px;
    }
}
.imgFormatSec {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-block: 30px;

    .format {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        & p {
            margin: 0;
        }

        .formatImg {
            width: 55px;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;

            & p {
                font-size: 17px;
            }
        }

        .formatContent p:first-child {
            font-size: 17px;
        }

        .formatContent p:last-child {
            font-size: 13px;
        }
    }
}
// Modal Middle Sectiion Download File Section  End


// Profile Info start
.profileInfo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 31px;

    .profileInfo-content {
        & h1 {
            font-size: 24px;
            text-transform: capitalize;
            margin: 0;
        }

        & p {
            font-size: 18px;
            margin: 0;
        }
    }
}
// Profile Info end

// Details Table
.projectDetails {
    width: 100%;
    margin-bottom: 31px;
    border-collapse: separate;
    border-spacing: 0 10px;

    & tr {
        & td:first-child {
            font-size: 17px;
        }

        & td:last-child {
            font-size: 17px;
        }
    }
}
// Details Table

// Customer Profile Page
.customerProfile {
    .ant-btn-sm{
        width: 44px;
        height: 27px;
        border-radius: 4px;
        float: right;
    }
    .projectDetails {
        margin-bottom: 99px;

        & tr {
            & td:first-child {
                font-size: 13px;
                width: 50%;
            }

            & td:last-child {
                font-size: 13px;
                width: 50%;
            }
        }
    }

    .customerRating {
        & p:first-child {
            font-size: 16px;
            margin-bottom: 7px;

        }

        & p:last-child {
            font-size: 13px;
            margin-bottom: 7px;
            padding: 13px 31px 12px 30px;
            border-radius: 15px;
            opacity: 1;

        }
        .ant-rate{
            margin-bottom: 19px;
        }
    }
    .topSec{
        display: flex;
        justify-content: space-between;
    }
}
// Customer Profile Page


// Outline Danger Button
.OutlineDangerBtn {
    max-width: 250px;
    width: 100%;
    height: 46px;
    font-size: 17px;
    border-radius: 11px;
    opacity: 1;
    text-transform: uppercase;
}
// Outline Danger Button

// Ant design Invoice Modal Styling
.InvoiceModalBody {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .ModalLeftSec {
        width: 100%;
        height: 850px;
        border-radius: 24px;
        opacity: 1;
        padding: 29px;

        & h1 {
            font-size: 24px;
        }

        .projectName {
            width: 100%;
            margin-block: 31px;

            & h1 {
                font-size: 20px;
                text-transform: capitalize;
                margin-bottom: 10px;
            }

            & p {
                font-size: 17px;
                margin: 0;
            }

        }

        .projectDetails {
            width: 100%;
            margin-bottom: 31px;
            border-collapse: separate;
            border-spacing: 0 8px;

            & tr {
                & td:first-child {
                    font-size: 17px;
                }

                & td:last-child {
                    font-size: 17px;
                }
            }
        }
        .OutlineDangerBtn {
            margin-bottom: 50px;
        }
        .primaryBtn {
            padding: 8px 27px;
            margin-bottom: 9px;
        }
        .successBtn {
         margin-bottom: 50px;
        }
         .InvoiceOptions {
            max-width: 100%;
            text-align: center;
             .InvoiceOptionsChild {
                 width: 35px;
                 height: 34px;
                 border-radius: 8px;
                 opacity: 1;
                 display: flex;
                 justify-content: center;
                 align-items: center;
             }
             & span {
                font-size: 8px;
             }
         }
    }

    .ModalMiddleSec {
        width: 100%;
        height: 850px;
        padding: 39px 0 50px 19px;

        & h1 {
            font-size: 31px;
            text-transform: uppercase;
            margin-bottom: 44px;
            font-weight: bold;
        }
        .projectDetails {
            width: 100%;
            margin-bottom: 73px;
            border-collapse: separate;
            border-spacing: 0 10px;
            thead{
    
                tr{
                    td{
                        padding-block: 8px;
                        font-size: 12px;
                    }
                    padding-top: 1000px;
                    td:nth-child(1){
                        padding-left: 10px;
                    }
                    td:nth-child(2){
                        text-align: center;
                        width: 60%;
                    }
                }
            }
            tbody{
                tr{
                    td{
                        font-size: 14px;
                        font-weight: bold;
                    }
                    td:nth-child(2){
                        width: 60%;
                    }
                }
              
            }
        }
        ul{
            list-style: none;
            padding: 0;
            margin-left: auto;
            max-width: 195px;
            li{
                display: flex;
                justify-content: space-between;
                p{
                    margin-bottom: 6px;
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }

    }
}
// Ant design Invoice Modal Styling

// Invoice Page Styling Start
.invoice-progress-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .invoice-text {
        margin-left: 10px;

        & p{
            margin-bottom: 0;
        }

       & p:first-child {
            font-size: 25px;
            color: #3B3B72;
        }
        & p:last-child {
            color: #A662FB;
            font-size: 13px;
        }
    }
}
// Invoice Page Styling End